.react-calendar__tile--disabled {
    color: red !important;
    /* Optional: Add background color to make it more distinct */
    background-color: #fdd;
  }

  .react-date-range .rdr-DayDisabled {
    color: red; /* Change text color to red */
    background-color: #fdd; /* Optionally change the background color */
    cursor: not-allowed; /* Change cursor to indicate the date is disabled */
  }

  .react-datepicker__day--disabled {
    color: #000000; /* Light gray for disabled dates */
    pointer-events: none; /* Disable pointer events */
    background-color: #ec6c6c; /* Light background */
  }

  /* .rdrDayDisabled {
    color: #ff0000 !important; 
    background-color: rgb(236, 14, 14) !important;
  } */

  .disabled-before-min {
    width: 100%;
    background-color: #d3d3d3 !important; /* Color for dates before minDate */
    cursor: not-allowed;
    color: #000000 !important;
  }
  
  .disabled-after-min {
    width: 100%;
    background-color: #ff3d3d; /* Color for dates disabled by other rules */
    pointer-events: none;
  }

  .disabling-after-min {
    width: 100%;
    background-color: #ffff50 !important; /* Color for dates disabled by other rules */
    pointer-events: none;
  }

  .notdisabled-after-min {
    width: 100%;
    background: #a6f8ad; /* Color for dates disabled by other rules */
    
  }

  .rdrCalendarWrapper{
    color: #000000;
    font-size: 12px;
    background: transparent;
  }

.rdrDay {
    background-color: #67ff63; /* Custom background color */
    color: black !important;
  }

  .rdrDayDisabled {
    background-color: #d3d3d3; /* Light gray or any color to indicate disabled state */
    color: #7c7c7c !important; /* Gray text color for disabled days */
    pointer-events: none; /* Change cursor to indicate disabled state */
  }
/* 
  .rdrDayDisabled {
    background-color: rgb(104, 39, 39);
    .rdrDayNumber span{
      color: #aeb9bf;
    }
    .rdrInRange, .rdrStartEdge, .rdrEndEdge, .rdrSelected, .rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview{
      filter: grayscale(100%) opacity(60%);
    }
  } */




  .rdrDateDisplayWrapper{
    background-color: transparent;
  }

  .rdrDateDisplayItem{
    display: none;
    border-radius: 4px;
    background-color: #001524;
    box-shadow: 0 1px 2px 0 rgba(35, 57, 66, 0.21);
    border: 1px solid transparent;
    input{
      cursor: pointer;
      height: 2.5em;
      line-height: 2.5em;
      border: 0px;
      background: transparent;
      width: 100%;
      color: white;
    }
  }

.rdrWeekDay {
  font-weight: 400;
  line-height: 2.667em;
  color: black;
}

.rdrDateDisplayItemActive{
  border-color: white;
}

.rdrMonthPicker, .rdrYearPicker{
  margin: 0 5px;
  color: black !important;
}

.rdrMonthName {
  color: black !important;
}


.rdrNextPrevButton {
  background-color: #000000;
  color: white;
}

.rdrNextPrevButton:hover {
  background-color: #000000;
  color: white;
}

.rdrPprevButton {
  i {
    border-width: 4px 6px 4px 4px;
    border-color: transparent rgb(255, 255, 255) transparent transparent;
    transform: translate(-3px, 0px);
  }
}

.rdrNextButton {
  i {
    margin: 0 0 0 7px;
    border-width: 4px 4px 4px 6px;
    border-color: transparent transparent transparent rgb(255, 255, 255);
    transform: translate(3px, 0px);
  }
}

.rdrMonthAndYearPickers {
  color: black !important;
}

.rdrDayPassive{
  pointer-events: none;
  background-color: #d3d3d3 !important;
  color: #7c7c7c !important;
  .rdrDayNumber span{
    color: #7c7c7c;
  }
  .rdrInRange, .rdrStartEdge, .rdrEndEdge, .rdrSelected, .rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview{
    display: none;
    background-color: #d3d3d3 !important;
  }
}





